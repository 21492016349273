.languageSwitcher {
    position: relative;
    display: inline-block;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    padding: 0.3rem;

    background-color: #3a9b92;
    background-size: 60%;

    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
    border: 3px solid transparent;
    border-radius: 50%;

    -webkit-transition:
        box-shadow,
        border 0.3s;
    -moz-transition:
        box-shadow,
        border 0.3s;
    transition:
        box-shadow,
        border 0.3s;
}


.button:hover {
    border: 3px solid white;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.4);
    color: white;
}

.iconContainer {
    display: flex;
    width: 36px;
    height: 24px;
}

.dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100%;
    margin-top: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    list-style: none;
    z-index: 10;
    padding: 0 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdownItem {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 12px;
    cursor: pointer;
}

.dropdownItem:hover {
    background-color: #eee;
}

.dropdownItemSelected {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #dcdcdc;
    font-weight: 600;
    border-radius: 12px;
    cursor: pointer;
}

.dropdownLabel {
    font-size: 14px;
    font-weight: 400;
    color: #3a9b92;
}
