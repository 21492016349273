.languageSwitcher {
    position: relative;
    display: inline-block;
}

.button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 4px;
    background-color: "transparent";
    color: #333;
    border: 3px solid #fff;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
    fill: #3a9b92;
}

.button:hover {
    border: 3px solid #3a9b92;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.4);
}

.buttonIcon {
    fill: #3a9b92;
    height: 24px;
    width: 40px;
}

.dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100%;
    margin-top: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    list-style: none;
    z-index: 10;
    padding: 0 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdownItem {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 12px;
    cursor: pointer;
    text-decoration: none;
}

.dropdownItem:hover {
    background-color: #eee;
}

.dropdownItemSelected {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #dcdcdc;
    font-weight: 600;
    border-radius: 12px;
    cursor: pointer;
}

.dropdownIcon {
    display: inline-flex;
    width: 40px;
    height: 40px;
    fill: #3a9b92;
}

.dropdownLabel {
    font-size: 14px;
    font-weight: 400;
    color: #3a9b92;
}
