.venueTopicsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
  font-weight: 300;
  color: #333;
  margin-bottom: 128px;
}

.venueTopicsContainer h1 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.venueTopicContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.venueTopicPreview {
  max-width: 845px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  text-decoration: none;
  color: inherit;
}

.venueTopicPreviewDivider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 32px;
  width: 60%;
  height: 1px;
  background-color: #bbb;
}

.venueTopicPreviewContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 830px;
  text-align: left;
}

.venueTopicPreviewDescription {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding-right: 8px;
}

.venueTopicPreviewDescription h2 {
  font-size: 24px;
  line-height: 1.2;
  color: #2c3e50;
  margin-bottom: 0;
}

.venueTopicPreviewDescription p {
  font-size: 16px;
  color: #657a7b;
  margin-top: 4px;
}

.venueCategories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.categoryBadge {
  background-color: #e0e0e0;
  color: #333;
  border-radius: 12px;
  padding: 4px 12px;
  margin: 4px 4px 0 0;
  font-size: 12px;
}

.venueTopicPreviewContent figure {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;
  min-width: 100px;
  padding: 0;
}

.venueTopicPreviewContent figure img {
  max-width: 400px;
  width: 100%;
  border-radius: 4px;
  object-fit: scale-down;
}

@media (max-width: 475px) {
  .venueTopicPreviewContent {
    flex-direction: column;
  }
}
